// TalkSwabs.js - Progressive Form for Collecting User Information
import React, { useState } from "react";
import "./TalkSwabs.css";
import { SpeedIcons } from "../../../../../assets/icons";
import { GenericIcons } from "../../../../../assets/icons";
import {IconButton} from "../../../../buttons/icon_button/IconButton";

const TalkSwabs = () => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        companyName: "",
        email: "",
        phone: "",
        industry: "",
        testingVolume: "",
        importantFactors: [],
        subscribe: false
    });
    const [step, setStep] = useState(1);

    const industries = ["Healthcare", "Manufacturing", "Education", "Retail", "Government", "Other"];
    const volumes = ["Less than 100", "100-500", "500-1000", "More than 1000"];
    const importantOptions = ["Speed", "Accuracy", "Price", "Customer Support", "Ease of Use"];

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
    };

    const handleMultiSelect = (option) => {
        setFormData((prevData) => {
            const isSelected = prevData.importantFactors.includes(option);
            const updatedFactors = isSelected
                ? prevData.importantFactors.filter((item) => item !== option)
                : [...prevData.importantFactors, option];
            return { ...prevData, importantFactors: updatedFactors };
        });
    };

    const handleNext = () => {
        setStep(step + 1);
    };

    const handlePrevious = () => {
        setStep(step - 1);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form submitted:", formData);
        // Add form submission logic here
    };

    return (
        <div className="talk-swabs-form-wrapper">
            <form className="talk-swabs-form" onSubmit={handleSubmit}>
                <div className="progress-bar">
                    <div className={`progress-step ${step >= 1 ? "active" : ""}`}>
                        <img src={SpeedIcons.PersonIcon} alt="Personal Details" />
                    </div>
                    <div className={`progress-step ${step >= 2 ? "active" : ""}`}>
                        <img src={SpeedIcons.CompanyIcon} alt="Company Details" />
                    </div>
                    <div className={`progress-step ${step >= 3 ? "active" : ""}`}>
                        <img src={SpeedIcons.EmailIcon} alt="Contact Details" />
                    </div>
                    <div className={`progress-step ${step >= 4 ? "active" : ""}`}>
                        <img src={SpeedIcons.CheckIcon} alt="Preferences" />
                    </div>
                </div>
                <h2 className="form-title">Talk to Us About Your Testing Needs</h2>
                {step === 1 && (
                    <div className="form-group">
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            placeholder="First Name"
                            value={formData.firstName}
                            onChange={handleChange} required onKeyDown={(e) => { if (e.key === 'Enter' || e.key === 'Tab') { handleNext(); } }}
                        />
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            placeholder="Last Name"
                            value={formData.lastName}
                            onChange={handleChange}
                            required
                        />
                        <div className="button-group-right"><button type="button" className="next-button full-width next-button-full-width" onClick={handleNext}>Next</button>
                        </div>
                    </div>
                )}
                {step === 2 && (
                    <div className="form-group">
                        <input
                            type="text"
                            id="companyName"
                            name="companyName"
                            placeholder="Company Name"
                            value={formData.companyName}
                            onChange={handleChange}
                            required
                        />
                        <select id="industry" name="industry" value={formData.industry} onChange={handleChange} required>
                            <option value="" disabled>Select your industry</option>
                            {industries.map((industry, index) => (
                                <option key={index} value={industry}>{industry}</option>
                            ))}
                        </select>
                        <div className="button-group-right">
                            <div className="back-arrow upper-left" onClick={handlePrevious}><img src={SpeedIcons.BackArrowIcon} alt="Back" /></div>
                            <button type="button" className="next-button full-width" onClick={handleNext}>Next</button>
                        </div>
                    </div>
                )}
                {step === 3 && (
                    <div className="form-group">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <div className="form-group-checkbox updated-checkbox-position">
                            <input type="checkbox" id="subscribe" name="subscribe" checked={formData.subscribe} onChange={handleChange} />
                            <label htmlFor="subscribe">Stay up to date with the latest news and exclusive offers by subscribing to our email list.</label>
                        </div>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            placeholder="Phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                        <div className="button-group">
                            <div className="exit-button upper-right">
                            <IconButton hoverIcon={GenericIcons.ExitIcon} defaultIcon={GenericIcons.CloseIcon} />
                            </div>

                            <button type="button" className="next-button" onClick={handleNext}>Next</button>
                        </div>
                    </div>
                )}
                {step === 4 && (
                    <div className="form-group">
                        <select id="testingVolume" name="testingVolume" value={formData.testingVolume}
                                onChange={handleChange} required>
                            <option value="" disabled>Select your testing volume</option>
                            {volumes.map((volume, index) => (
                                <option key={index} value={volume}>{volume}</option>
                            ))}
                        </select>
                        <label>What's Most Important to You? (Select all that apply)</label>
                        <div className="multi-select-options">
                            {importantOptions.map((option, index) => (
                                <div key={index} className="multi-select-option">
                                    <input
                                        type="checkbox"
                                        id={`important-${index}`}
                                        checked={formData.importantFactors.includes(option)}
                                        onChange={() => handleMultiSelect(option)}
                                    />
                                    <label htmlFor={`important-${index}`}>{option}</label>
                                </div>
                            ))}
                        </div>
                        <div className="button-group">
                            <button type="button" className="previous-button" onClick={handlePrevious}>Previous</button>
                            <button type="submit" className="submit-button">Submit</button>
                        </div>
                    </div>
                )}
            </form>
        </div>
    );
};

export default TalkSwabs;