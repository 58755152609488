import "./PublicHome.css";
import React, { useEffect } from "react";
import PublicHeader from "../../../components/headers/public_header/PublicHeader";
import { useShopifyLogout } from "../../../utils/authUtils";
import LabFusionLogo from "../../../assets/logo/LabFusionLogo.png";
import SpeedSwabsLogo from "../../../assets/logo/SpeedSwabLogo.png";
import LabbLogo from "../../../assets/logo/LabbLogo.png";
import ShopifyHeader from "../../../components/headers/shopify_header/ShopifyHeader";
import FusionDashboard from "../../dashboards/fusion_dashboard/FusionDashboard";
import LabbDashboard from "../../dashboards/labb_dashboard/LabbDashboard";
import FusionFooter from "../../../components/footers/fusion_footer/FusionFooter";
import PublicFooter from "../../../components/footers/public_footer/PublicFooter";
import SpeedSwabsDashboard from "../../dashboards/speed_swab_dashboard/SpeedSwabDashboard";
import SpeedSwabsFooter from "../../../components/footers/speed_swab_footer/SpeedSwabsFooter";
import SpeedSwabsHeader from "../../../components/headers/speed_swabs_header/SpeedSwabsHeader";

const PublicHomePage = () => {
    const shopifyAccessToken = sessionStorage.getItem("shopifyAccessToken");
    const hostname = window.location.hostname;
    const onLogout = useShopifyLogout();

    // Determine which logo to display
    const logoSrc =
        hostname === "fusion.labb.com" ?
        LabFusionLogo
        : hostname === "reign.dev.freddy.app.labbvision.com"
        ? SpeedSwabsLogo
        : hostname === "labb.dev.freddy.app.labbvision.com"
        ? SpeedSwabsLogo
        : hostname === "remote.dev.freddy.app.labbvision.com"
        ? SpeedSwabsLogo
        : hostname === "shop.speedswabs.com"
        ? SpeedSwabsLogo : LabbLogo;

    // Select the appropriate dashboard component based on the domain
    const DashboardContent =
        hostname === "fusion.labb.com" ? FusionDashboard
            : hostname === "reign.dev.freddy.app.labbvision.com"
                ? SpeedSwabsDashboard
            : hostname === "remote.dev.freddy.app.labbvision.com"
                ? SpeedSwabsDashboard
            : hostname === "labb.dev.freddy.app.labbvision.com"
                ? SpeedSwabsDashboard
            : hostname === "shop.speedswabs.com"
                ? SpeedSwabsDashboard : LabbDashboard;

    // Select the appropriate footer component
    const FooterComponent =
        hostname === "fusion.labb.com" ? FusionFooter
            : hostname === "reign.dev.freddy.app.labbvision.com"
                ? SpeedSwabsFooter
            : hostname === "remote.dev.freddy.app.labbvision.com"
                ? SpeedSwabsFooter
            : hostname === "labb.dev.freddy.app.labbvision.com"
                ? SpeedSwabsFooter
            : hostname === "shop.speedswabs.com"
                ? SpeedSwabsFooter : PublicFooter;

    // Set document title based on domain
    const docTitle =
        hostname === "fusion.labb.com" ? "Lab Fusion | Dashboard"
            : hostname === "reign.dev.freddy.app.labbvision.com"
                ? "SpeedSwabs | Dashboard"
            : hostname === "remote.dev.freddy.app.labbvision.com"
                ? "SpeedSwabs | Dashboard"
            : hostname === "labb.dev.freddy.app.labbvision.com"
                ? "SpeedSwabs | Dashboard"
            : hostname === "shop.speedswabs.com"
                ? "SpeedSwabs | Dashboard" : "Labb | Dashboard";

    useEffect(() => {
        document.title = docTitle;
    }, [docTitle]);

    return (
        <div className="public-homepage">
            {shopifyAccessToken ? (
                <ShopifyHeader logoSrc={logoSrc} onLogout={onLogout} />
            ) : hostname === "reign.dev.freddy.app.labbvision.com" ? (
                <SpeedSwabsHeader logoSrc={logoSrc} onLogout={onLogout} />
            ) : hostname === "labb.dev.freddy.app.labbvision.com" ? (
                <SpeedSwabsHeader logoSrc={logoSrc} onLogout={onLogout} />
            ) : hostname === "remote.dev.freddy.app.labbvision.com" ? (
                <SpeedSwabsHeader logoSrc={logoSrc} onLogout={onLogout} />
            ) : hostname === "shop.speedswabs.com" ? (
                <SpeedSwabsHeader logoSrc={logoSrc} onLogout={onLogout} />
            ) : (
                <PublicHeader logoSrc={logoSrc} onLogout={onLogout} />
            )}
            <main className="dashboard-content">
                <DashboardContent />
            </main>
            <FooterComponent />
        </div>
    );
};

export default PublicHomePage;
